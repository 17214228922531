* {
  box-sizing: border-box;
  font-family: 'Inter', serif;
}

body, #root {
  background-color: #131313;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

#root {
  color: white;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

p {
  margin: 0;
}
